import { getTenantConfig } from "../services/api";
import toast from "react-hot-toast";
import { setMunicipio } from "../services/data";
import { clear } from "../services/db";

export async function checkAutenticationByUrl() {
  const textAreaPreferenceSize = localStorage.getItem('textareaSize');
  let params = window.location.search.split("&");
  const check = params.filter((param) => param.includes("x-auth-token=") || param.includes("x-tn-token=")).length !== 0;

  if (check) {
    clear().then();
    textAreaPreferenceSize && localStorage.setItem('textareaSize', textAreaPreferenceSize);
  }

  if (params.length > 2) {
    params[1] = "";
    params.shift();
  }
  params[0] = params[0].replace("?", "");

  params.map((param) => {
    if (param.includes("x-auth-token=")) {
      localStorage.setItem("xAuthToken", param.replace("x-auth-token=", ""));
    }

    if (param.includes("x-tn-token=")) {
      localStorage.setItem("xTnToken", param.replace("x-tn-token=", ""));
    }
  });

  if (check) {
    const projectInfo = await getTenantConfig();
    if (!projectInfo) {
      toast.error("Não foi possível obter as configurações de projeto");
    }

    setMunicipio(projectInfo!.cidade);
    localStorage.setItem("errors", "{}");
  }

  return check;
}
