import { Dialog, DialogActions, DialogContent, DialogTitle as MuiDialogTitle, Button, Typography } from "@mui/material";

interface WarningDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

function WarningDialog(props: WarningDialogProps) { const { open, onClose, title, message } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <MuiDialogTitle>
        <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </MuiDialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="success" 
          sx={{
            color: "white",
            backgroundColor: "success.main",
            '&:hover': {
              backgroundColor: "success.dark",
            },
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
