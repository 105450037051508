import TabelaAuditoria from "./Table";
import { alpha, Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import logo from "../../assets/logo.png";
import logoLight from "../../assets/logo-pos.png";
import { DialogVersion } from "../Dashboard/DialogVersion";
import pj from "../../../package.json";
import { environments } from "../../environments";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import React, { useContext, useEffect } from "react";
import { ColorModeContext } from "../../providers/Theme";
import { useHistory } from "react-router-dom";
import { getTokens } from "../../services/data";
import { checkAutenticationByUrl } from "../../utils/checkAuthByUrl";

export default function AuditoriaCadastro() {
  const theme = useTheme();
  const color = useContext(ColorModeContext);
  const history = useHistory();

  const isCadastro = window.location.pathname.includes("auditoria/cadastro");

  async function asyncFunctionInit() {
    let tokens = getTokens();
    const responseCheck = await checkAutenticationByUrl();
    if (responseCheck) {
      return;
    }

    if (!tokens['x-auth-token'] || !tokens['x-tn-token']) {
      window.location.pathname.includes("cadastro") && localStorage.setItem("callback", window.location.pathname + window.location.search);
      window.location.pathname.includes("cadastro") && history.push("/?callback=" + window.location.pathname + window.location.search);
      return;
    }

    localStorage.removeItem("callback");
  }

  useEffect(() => {
    asyncFunctionInit().then();
  }, []);

  const styledTab = {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    paddingBottom: 3,
    cursor: "pointer",
    borderBottom: isCadastro ? `1px ${theme.palette.success.main} solid` : "none",
    color: isCadastro ? theme.palette.success.main : "inherit"
  };

  const styledTabSistema = {
    ...styledTab,
    borderBottom: !isCadastro ? `1px ${theme.palette.success.main} solid` : "none",
    color: !isCadastro ? theme.palette.success.main : "inherit"
  };

  return (
    <>
      <Box
        sx={{
          width: "95vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          id="logo-container"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <img className="logo-dashboard" src={theme.palette.mode === "dark" ? logo : logoLight} alt="logo" />

              <Divider
                sx={{
                  marginRight: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                }}
                orientation="vertical"
                flexItem
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"700"}
                  fontStyle={{
                    color: alpha(theme.palette.text.secondary, 0.4),
                  }}
                >
                  Auditoria
                </Typography>

                <DialogVersion>
                  {pj.version} - {environments.ambiente}{" "}
                </DialogVersion>
              </Box>
            </Box>

            <Box id="dark-mode-toggle" sx={{ display: "flex", gap: theme.spacing(1) }}>
              <IconButton onClick={color.toggleColorMode} color="inherit">
                {theme.palette.mode === "dark" ? <Brightness7Icon /> : <DarkModeIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "90%", margin: "auto", display: "flex", justifyContent: "center", marginTop: 6 }}>
        <Box sx={styledTabSistema} onClick={() => history.push("/auditoria/sistema")}>
          AUDITORIA DO SISTEMA
        </Box>

        <Box sx={styledTab} onClick={() => history.push("/auditoria/cadastro")}>
          AUDITORIA DO MÓDULO DE CADASTRO
        </Box>
      </Box>

      <Box sx={{ marginTop: 6 }}>
        <TabelaAuditoria />
      </Box>
    </>
  );
}
