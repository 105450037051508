import { useEffect, useRef, useState } from "react";
import { isCurrentColorModeDark } from "../../../../providers/Theme";

export function useTextArea() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [textareaSize, setTextareaSize] = useState({ width: '300px', height: '150px' });
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const styleTextArea = {
    width: "100%",
    height: "100px",
    maxWidth: "90vw",
    maxHeight: "75vh",
    minHeight: "100px",
    minWidth: "300px",
    color: isCurrentColorModeDark() ? "#C7D0DD" : "#1C2025",
    background: isCurrentColorModeDark() ? "#1C2025" : "#fff",
  };

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "12px",
    padding: "24px",
  };

  useEffect(() => {
    const savedSize = localStorage.getItem('textareaSize');
    if (savedSize) {
      setTextareaSize(JSON.parse(savedSize));
    }
  }, []);

  const handleResize = () => {
    if (textareaRef.current) {
      const newSize = {
        width: `${textareaRef.current.offsetWidth}px`,
        height: `${textareaRef.current.offsetHeight}px`,
      };
      setTextareaSize(newSize);
      localStorage.setItem('textareaSize', JSON.stringify(newSize));
    }
  };

  return {
    openDialog,
    setOpenDialog,
    styleTextArea,
    style,
    handleResize,
    textareaRef,
    textareaSize
  };
}
