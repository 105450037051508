import { Box, Button, Card, CardContent, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import background from "../../assets/background.jpg";
import logo from "../../assets/logo.png";
import { environments } from "../../environments";
import { getTenantConfig, login } from "../../services/api";
import { setErrors, setMunicipio, setTokens } from "../../services/data";
import { clear } from "../../services/db";
import pj from "./../../../package.json";
import "./styles.css";

export default function Login() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [urlParams, setUrlParams] = useState<{
    id: string | number | undefined;
    xTnToken: string | undefined;
    xAuthToken: string | undefined;
  }>({ id: undefined, xTnToken: undefined, xAuthToken: undefined });

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    localStorage.getItem("callback") !== null && window.history.pushState({}, "", `${window.location.pathname}?callback=${localStorage.getItem("callback")}`);

    urlParams.id = query.get("id") || undefined;
    urlParams.xTnToken = query.get("x-tn-token") || undefined;
    urlParams.xAuthToken = query.get("x-auth-token") || undefined;

    if (urlParams.id && urlParams.xTnToken && urlParams.xAuthToken) {
      loginByUrl();
    }
  }, []);

  async function loginByUrl() {
    clear();

    setIsLoading(true);

    setTokens(urlParams.xTnToken!, urlParams.xAuthToken!);
    setErrors();

    const projectInfo = await getTenantConfig();

    if (!projectInfo) {
      setIsLoading(false);
      toast.error("Não foi possível obter as configurações de projeto");
    }

    setMunicipio(projectInfo!.cidade);
    setIsLoading(false);

    history.push(`/dashboard/${urlParams.id}`);
  }

  async function onSubmit(data: any) {
    let email = data.email;
    let senha = data.senha;
    const callback = localStorage.getItem("callback");
    const textAreaPreferenceSize = localStorage.getItem('textareaSize');

    setIsLoading(true);

    clear();
    try {
      const user = await login(email, senha);

      if (!user) {
        setIsLoading(false);
        toast.error("Usuário ou senha inválidos");
        throw new Error("Usuário ou senha inválidos");
      }

      textAreaPreferenceSize && localStorage.setItem('textareaSize', textAreaPreferenceSize);
      setTokens(user.tnToken, user.authToken);
      setErrors();

      const projectInfo = await getTenantConfig();

      if (!projectInfo) {
        setIsLoading(false);
        toast.error("Não foi possível obter as configurações de projeto");
      }

      setMunicipio(projectInfo!.cidade);
      setIsLoading(false);
      if (callback !== null) {
        history.push(callback);
      } else {
        history.push("/dashboard/new");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <Box
        sx={{
          paddingBottom: "40px"
        }}
      >
        <img className="logo" src={logo} alt="logo" />
      </Box>
      <Card
        sx={{
          width: "400px"
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography textAlign="center" variant="h6">
                Faça login em sua conta
              </Typography>
              <TextField
                type="text"
                variant="filled"
                placeholder="Email do usuário"
                sx={{
                  paddingTop: "1rem"
                }}
                {...register("email", {
                  required: "Este campo é obrigatório"
                })}
              />
              <TextField
                type="password"
                variant="filled"
                placeholder="Senha"
                sx={{
                  paddingTop: "1rem"
                }}
                {...register("senha", { required: "Este campo é obrigatório" })}
              />
              <Divider
                sx={{
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem"
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  padding: "1rem"
                }}
                disabled={isLoading}
              >
                {!isLoading ? "Acessar" : "Acessando"}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>

      <Typography
        sx={{
          paddingTop: "100px",
          color: "primary.contrastText"
        }}
        variant="caption"
      >
        Versão {pj.version} - {environments.ambiente}
      </Typography>
    </Box>
  );
}
