import * as React from "react";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import { apiGateway } from "../../../repositories/api.gateway";
import { useQuery } from "@tanstack/react-query";
import { Auditoria, GenericObject } from "../../../interfaces";
import { getForms } from "../../../services/api";
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Menu } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface ISearchData {
  dataInicio: moment.Moment | null;
  dataFim: moment.Moment | null;
}

type Order = "asc" | "desc";

export default function TabelaAuditoria() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [camada, setCamada] = React.useState<string>("");
  const [propriedade, setPropriedade] = React.useState<string>("");
  const [valuePropriedade, setValuePropriedade] = React.useState<string>("");
  const [search, setSearch] = React.useState<string>("");
  const [dataSearch, setDataSearch] = React.useState<ISearchData>({ dataInicio: null, dataFim: null });
  const [descricao, setDescricao] = React.useState<string>("");
  const [usuario, setUsuario] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setIsExporting] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Auditoria | "">("");
  const AuditoriaKeys: Array<keyof Auditoria> = [
    "id",
    "camada",
    "descricao",
    "usuario",
    "token",
    "data",
    "body_novo",
    "body_antigo",
  ];

  useEffect(() => {
    const params = window.location.search.split("&");

    if (params.length > 2) {
      params[1] = "";
      params.shift();
    }

    params.map((param, index) => {
      if (param.includes("camada=eq.")) {
        setCamada(param.split("camada=eq.")[1]);
      }

      if (param.includes("data=gte.")) {
        setDataSearch({
          dataFim: moment(params[index + 1].split("data=lt.")[1], "YYYY-MM-DDTHH:mm:ss.SSSSSS"),
          dataInicio: moment(param.split("data=gte.")[1], "YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        });
      }

      if (param.includes("descricao=ilike.")) {
        setDescricao(decodeURIComponent(param.split("descricao=ilike.")[1].replaceAll("*", "")));
      }

      if (param.includes("usuario=ilike.")) {
        setUsuario(decodeURIComponent(param.split("usuario=ilike.")[1].replaceAll("*", "")));
      }

      if (param.includes("or=(body_novo-")) {
        setPropriedade(param.split("or=(body_novo-%3E%3E")[1].split(".")[0]);
        setValuePropriedade(param.split("or=(body_novo-%3E%3E")[1].split(".")[4].replace(")", "").replaceAll("*", ""));
      }

      if (param.includes("order=")) {
        const orderParam = param.split("order=")[1];
        const [field, direction] = orderParam.split(".");

        setOrder(direction === "asc" || direction === "desc" ? direction : "asc");

        if (field && (field === "" || field in AuditoriaKeys)) {
          setOrderBy(field as keyof Auditoria);
        } else {
          setOrderBy("");
        }
      }

      setSearch(
        params
          .filter((param) => !param.includes("x-auth-token=") && !param.includes("x-tn-token="))
          .join("&")
          .replace("?", "&"),
      );
    });
  }, []);

  useEffect(() => {
    let params = window.location.search.split("&");

    if (params.length > 2 && params[0].includes("limit=") && params[1].includes("offset=")) {
      params[0] = `${params[0].split("=")[0]}=${rowsPerPage}`;
      params[1] = `${params[1].split("=")[0]}=${rowsPerPage * page}`;

      const newUrl = `${window.location.pathname}${params.join("&")}`;
      window.history.pushState({}, "", newUrl);
    } else {
      const limit = `?limit=${rowsPerPage}`;
      const offset = `&offset=${rowsPerPage * page}&`;
      params[0] = `${params[0]}`.substring(1);
      const newUrl = `${window.location.pathname}${limit}${offset}${params.join("&")}`;
      window.history.pushState({}, "", newUrl);
    }
  }, [page, rowsPerPage]);

  function handleChange(
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFunction: React.Dispatch<any>,
  ) {
    setFunction(event.target.value as string);
  }

  async function getAuditoria(): Promise<Auditoria[]> {
    const offset = page * rowsPerPage;
    const orderParam = orderBy ? `&order=${orderBy}.${order}` : "";
    const response = await apiGateway(`/rest/auditoria?limit=${rowsPerPage}&offset=${offset}${orderParam}${search}`);
    const totalPagesResponse = response.headers["content-range"].split("/")[1];
    setTotalPages((old) => (!isNaN(totalPagesResponse) ? totalPagesResponse : old));
    return response.data;
  }

  async function getFilters(): Promise<GenericObject> {
    const response: any = await apiGateway(`/rest/`);
    return response.data.definitions;
  }

  const queryAuditorias = useQuery({
    queryKey: ["auditoria", page, rowsPerPage, search, order, orderBy],
    queryFn: () => getAuditoria(),
    staleTime: 60000,
  });

  const queryFiltros = useQuery({ queryKey: ["filtros"], queryFn: () => getFilters(), staleTime: 120000 });

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleSearch() {
    const searchCamada = camada !== "" ? `&camada=eq.${camada}` : "";
    const searchPropriedade =
      valuePropriedade !== ""
        ? `&or=(body_novo-%3E%3E${propriedade}.ilike.*${valuePropriedade}*,body_antigo-%3E%3E${propriedade}.ilike.*${valuePropriedade}*)`
        : "";
    const searchData =
      dataSearch.dataFim !== null && dataSearch.dataInicio !== null
        ? `&data=gte.${dataSearch.dataInicio?.format(
            "YYYY-MM-DDTHH:mm:ss.SSSSSS",
          )}&data=lt.${dataSearch.dataFim?.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")}`
        : "";
    const searchDescricao = descricao !== "" ? `&descricao=ilike.*${descricao}*` : "";
    const searchUsuario = usuario !== "" ? `&usuario=ilike.*${usuario}*` : "";

    const urlSearch = `${searchCamada}${searchPropriedade}${searchData}${searchDescricao}${searchUsuario}`;
    setSearch(urlSearch || "");

    const offset = page * rowsPerPage;
    const orderParam = orderBy ? `&order=${orderBy}.${order}` : "";
    const newUrl = `${window.location.pathname}?limit=${rowsPerPage}&offset=${offset}${orderParam}${search}`;
    window.history.pushState({}, "", newUrl);
  }

  function handleRequestSort(property: keyof Auditoria) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function isValidSearch() {
    return (
      valuePropriedade === "" &&
      camada === "" &&
      (dataSearch.dataInicio === null || dataSearch.dataFim === null) &&
      descricao === "" &&
      usuario === ""
    );
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeDataInicio(date: moment.Moment | null) {
    const newData = { ...dataSearch, dataInicio: date };
    setDataSearch(newData);
    if (date && dataSearch.dataFim && date.isAfter(dataSearch.dataFim)) {
      setDataSearch(newData);
    }
  }

  function handleChangeDataFim(date: moment.Moment | null) {
    const newData = { ...dataSearch, dataFim: date };
    if (date && dataSearch.dataInicio && date.isBefore(dataSearch.dataInicio)) {
      alert("A data de fim não pode ser menor que a data de início.");
      return;
    }
    setDataSearch(newData);
  }

  function clearFilters() {
    setCamada("");
    setPropriedade("");
    setValuePropriedade("");
    setDataSearch({ dataInicio: null, dataFim: null });
    setDescricao("");
    setUsuario("");
    setSearch("");

    const newUrl = `${window.location.pathname}`;
    window.history.pushState({}, "", newUrl);
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function exportConsult(format: string) {
    setIsExporting(true);
    handleClose();

    try {
      const auditoriaExport = await getAuditoriaExport();

      if (auditoriaExport.length < 1) {
        toast("Nenhum item encontrado para o filtro aplicado!");
        setIsExporting(false);
        return;
      }

      if (format === "csv") {
        exportToCSV(auditoriaExport);
        toast.success("Consulta exportada com sucesso!");
      } else if (format === "xlsx") {
        exportToXLSX(auditoriaExport);
        toast.success("Consulta exportada com sucesso!");
      } else {
        toast("Formato não suportado!");
      }

      setIsExporting(false);
    } catch {
      toast("Um erro inesperado aconteceu, tente novamente!");
      setIsExporting(false);
    }
  }

  function exportToCSV(data: Record<string, any>) {
    const expandedData = data.map((row) => {
      const flattenedRow: Record<string, any> = {};
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === "object" && row[key] !== null) {
          Object.keys(row[key]).forEach((subKey) => {
            if (row[key][subKey] !== null) {
              flattenedRow[`${key}_${subKey}`] = row[key][subKey];
            }
          });
        } else if (row[key] !== null) {
          flattenedRow[key] = row[key];
        }
      });
      return flattenedRow;
    });

    const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{1,6})?)?$/;

    expandedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const value = row[key];
        if (typeof value === "string" && datePattern.test(value)) {
          const date = new Date(value);
          row[key] = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
        }
      });
    });

    const allHeaders = new Set<string>();
    expandedData.forEach((row) => {
      Object.keys(row).forEach((key) => allHeaders.add(key));
    });
    const headers = Array.from(allHeaders);

    const csvRows = expandedData.map((row) =>
      headers
        .map((header) => {
          const cellValue = row[header];
          return typeof cellValue === "string" ? `"${cellValue}"` : cellValue;
        })
        .join(","),
    );

    const csvContent = [`"${headers.join('","')}"`, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "auditoria_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function exportToXLSX(data: Record<string, any>) {
    const expandedData = data.map((row) => {
      const flattenedRow: any = {};
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === "object" && row[key] !== null) {
          Object.keys(row[key]).forEach((subKey) => {
            if (row[key][subKey] !== null) {
              flattenedRow[`${key}_${subKey}`] = row[key][subKey];
            }
          });
        } else if (row[key] !== null) {
          flattenedRow[key] = row[key];
        }
      });
      return flattenedRow;
    });

    const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{1,6})?)?$/;

    expandedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const value = row[key];
        if (typeof value === "string" && datePattern.test(value)) {
          const date = new Date(value);
          row[key] = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(expandedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AuditoriaExport");

    XLSX.writeFile(workbook, "auditoria_export.xlsx");
  }

  async function getAuditoriaExport() {
    const searchCamada = camada !== "" ? `&camada=eq.${camada}` : "";
    const searchPropriedade =
      valuePropriedade !== ""
        ? `&or=(body_novo-%3E%3E${propriedade}.ilike.*${valuePropriedade}*,body_antigo-%3E%3E${propriedade}.ilike.*${valuePropriedade}*)`
        : "";
    const searchData =
      dataSearch.dataFim !== null && dataSearch.dataInicio !== null
        ? `&data=gte.${dataSearch.dataInicio?.format(
            "YYYY-MM-DDTHH:mm:ss.SSSSSS",
          )}&data=lt.${dataSearch.dataFim?.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")}`
        : "";
    const searchDescricao = descricao !== "" ? `&descricao=ilike.*${descricao}*` : "";
    const searchUsuario = usuario !== "" ? `&usuario=ilike.*${usuario}*` : "";

    const urlSearch = `${searchCamada}${searchPropriedade}${searchData}${searchDescricao}${searchUsuario}`;
    setSearch(urlSearch || "");

    const offset = page * rowsPerPage;
    const orderParam = orderBy ? `&order=${orderBy}.${order}` : "";
    const newUrl = `${window.location.pathname}?limit=${rowsPerPage}&offset=${offset}${orderParam}${search}`;
    window.history.pushState({}, "", newUrl);

    const response = await apiGateway(`/rest/auditoria?${urlSearch}${orderParam}`);
    return response.data;
  }

  const [metadata, setMetadata] = React.useState<GenericObject[]>([]);

  async function fetchForms() {
    const forms = await getForms();
    if (forms) {
      setMetadata(extractCampos(forms));
    }
  }

  function extractCampos(data: GenericObject) {
    return Object.values(data).flatMap((itemArray) =>
      itemArray.flatMap((item: GenericObject) => item.projetosCamadasFormulariosCampos),
    );
  }

  React.useEffect(() => {
    fetchForms();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          width: "90%",
          margin: "auto",
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", gap: 3 }}>
          <Box sx={{ minWidth: "110px" }}>
            <FormControl fullWidth>
              <InputLabel id="listCamada">Camadas</InputLabel>
              <Select
                labelId="listCamada"
                id="listCamadaSelect"
                value={camada}
                label="Camadas"
                onChange={(e) => handleChange(e, setCamada)}
              >
                {queryFiltros.data &&
                  Object.keys(queryFiltros.data).length > 0 &&
                  Object.keys(queryFiltros.data)
                    .sort((a, b) => a.localeCompare(b))
                    .map((camada: string, index: number) => (
                      <MenuItem key={index} value={camada}>
                        {camada}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 3 }}>
            <FormControl sx={{ minWidth: "140px" }}>
              <InputLabel id="listCamada">Propriedades</InputLabel>

              <Select
                labelId="listPropriedades"
                id="listPropriedadesSelect"
                value={propriedade}
                label="Propriedades"
                onChange={(e) => handleChange(e, setPropriedade)}
                disabled={camada === ""}
              >
                {camada !== "" &&
                  queryFiltros.data &&
                  Object.keys(queryFiltros.data[camada]).length > 0 &&
                  Object.keys(queryFiltros.data[camada].properties)
                    .sort((a, b) => a.localeCompare(b))
                    .map((propriedade: string, index: number) => (
                      <MenuItem key={index} value={propriedade}>
                        {propriedade}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            <TextField
              id="search-propriedade"
              sx={{ minWidth: "140px" }}
              label="Pesquisar Propriedade"
              variant="outlined"
              type="search"
              value={valuePropriedade}
              disabled={propriedade === ""}
              onChange={(e) => handleChange(e, setValuePropriedade)}
            />
          </Box>

          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <DateTimePicker
                  disableFuture
                  label="Data de Início"
                  format="L HH:mm"
                  value={dataSearch.dataInicio}
                  onChange={handleChangeDataInicio}
                />

                <DateTimePicker
                  disableFuture
                  label="Data de Fim"
                  format="L HH:mm"
                  value={dataSearch.dataFim}
                  onChange={handleChangeDataFim}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          <Box>
            <TextField
              id="search-descrição"
              label="Pesquisar Descrição"
              variant="outlined"
              fullWidth
              type="search"
              value={descricao}
              onChange={(e) => handleChange(e, setDescricao)}
            />
          </Box>

          <Box>
            <TextField
              id="search-usuário"
              label="Pesquisar Usuário"
              variant="outlined"
              fullWidth
              value={usuario}
              type="search"
              onChange={(e) => handleChange(e, setUsuario)}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 3, marginTop: 3 }}>
          <Button color="success" onClick={() => alert("Em breve...")} disabled variant="contained">
            Adicionar Filtro
          </Button>

          <Button
            color="success"
            onClick={() => handleSearch()}
            sx={{ width: "143.7px" }}
            variant="contained"
            disabled={isValidSearch()}
          >
            Filtrar
          </Button>

          <Button color="warning" onClick={() => clearFilters()} sx={{ width: "143.7px" }} variant="contained">
            Limpar Filtros
          </Button>

          <Button color="secondary" onClick={handleClick} variant="contained" disabled={isExporting}>
            {!isExporting ? "Exportar Consulta" : "Carregando..."}
          </Button>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => exportConsult("xlsx")}>XLSX</MenuItem>
            <MenuItem onClick={() => exportConsult("csv")}>CSV</MenuItem>
          </Menu>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ width: "90%", margin: "auto" }}>
        <Table aria-label="Tabela de auditorias de cadastro">
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell>
                <TableSortLabel
                  active={orderBy === "id"}
                  direction={orderBy === "id" ? order : "asc"}
                  onClick={() => handleRequestSort("id")}
                >
                  Id
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "data"}
                  direction={orderBy === "data" ? order : "asc"}
                  onClick={() => handleRequestSort("data")}
                >
                  Data
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "camada"}
                  direction={orderBy === "camada" ? order : "asc"}
                  onClick={() => handleRequestSort("camada")}
                >
                  Camada
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "descricao"}
                  direction={orderBy === "descricao" ? order : "asc"}
                  onClick={() => handleRequestSort("descricao")}
                >
                  Descrição
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "usuario"}
                  direction={orderBy === "usuario" ? order : "asc"}
                  onClick={() => handleRequestSort("usuario")}
                >
                  Usuário
                </TableSortLabel>
              </TableCell>
              <TableCell>Mensagem</TableCell>
              <TableCell>Resumo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!queryAuditorias.isLoading &&
              queryAuditorias?.data?.map((row) => <Row key={row.id} row={row} metadata={metadata} />)}
            {queryAuditorias.isLoading &&
              Array.from({ length: 10 }).map((_, index) => (
                <TableRow key={index}>
                  {Array.from({ length: 8 }).map((_, indexCell) => (
                    <TableCell key={indexCell}>
                      <Skeleton variant="text" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[1, 2, 4, 5, 10, 25, 50, 100, 200]}
                count={Number(totalPages)}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"Linhas por página"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {queryAuditorias?.data?.length == 0 && (
        <h1 style={{ textAlign: "center" }}>Nenhum item encontrado para o filtro aplicado</h1>
      )}
    </>
  );
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row(props: { row: Auditoria; metadata: GenericObject[] }) {
  const { row, metadata } = props;

  const [open, setOpen] = React.useState(false);

  function haveSameKeys(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return true;
    } else {
      return false;
    }
  }

  function countChanges(body_antigo: any, body_novo: any) {
    if (!body_antigo) return "Um novo registro foi criado";
    if (!body_novo) return "Um registro foi deletado";
    try {
      let count = 0;

      for (const key in body_antigo) {
        if (body_antigo.hasOwnProperty(key) && body_novo.hasOwnProperty(key)) {
          if (JSON.stringify(body_antigo[key]) !== JSON.stringify(body_novo[key])) {
            count++;
          }
        }
      }
      return count === 0 ? "-" : `${count} modificações`;
    } catch (error) {
      return "-";
    }
  }

  function labelValue(tblNomeColuna: string, value: string) {
    const data = metadata.find((item) => item.tblNomeColuna === tblNomeColuna);
    if (data && data.camposSelect) {
      const label = data.camposSelect.find((item: GenericObject) => item.tblValorDb === String(value))?.nome;
      if (label) {
        return label;
      }
      return value;
    }
    return value;
  }

  function formatValue(key: string, value: string) {
    if (!row) return;

    if (key === "geom") {
      return "Visualize a geometria em 'Visualizar em detalhes'";
    }

    const isObject = value && typeof value === "object" && !Array.isArray(value);

    if (isObject) {
      return JSON.stringify(value, null, 2);
    }

    if (typeof value === "boolean") {
      return value ? "Sim" : "Não";
    }

    const label = labelValue(key, value);

    if (label) {
      return label;
    }

    if (value === "") {
      return "-";
    }

    return String(value);
  }

  function currentComponent() {
    if (!row) return;

    if (row.body_antigo === null && row.body_novo !== null) {
      const keys_body_novo = Object.keys(row.body_novo);

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campo</TableCell>
              <TableCell align="right">Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys_body_novo.map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right" sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {formatValue(key, row.body_novo[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    if (row.body_novo === null && row.body_antigo !== null) {
      const keys_body_antigo = Object.keys(row.body_antigo);

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campo</TableCell>
              <TableCell align="right">Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys_body_antigo.map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right" sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {formatValue(key, row.body_antigo[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    if (haveSameKeys(row.body_antigo, row.body_novo)) {
      return (
        <Box sx={{ display: "flex", gap: "6px", flexDirection: "column" }}>
          <Box sx={{ verticalAlign: "top" }}>
            <Box sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
              <b>Body antigo</b>: {JSON.stringify(row?.body_antigo, null, 2)}
            </Box>
          </Box>
          <Box sx={{ verticalAlign: "top" }}>
            <Box sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
              <b>Body novo</b>: {JSON.stringify(row?.body_novo, null, 2)}
            </Box>
          </Box>
        </Box>
      );
    }

    const keys_body_novo = Object.keys(row.body_novo || {});
    const keys_body_antigo = Object.keys(row.body_antigo || {});

    const allKeys = [...keys_body_novo, ...keys_body_antigo].filter((key, index, self) => self.indexOf(key) === index);

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Campo</TableCell>
            <TableCell align="right">Antigo</TableCell>
            <TableCell align="right">Novo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...allKeys].map((key) => {
            const oldValue = formatValue(key, row.body_antigo[key]);
            const newValue = formatValue(key, row.body_novo[key]);

            if (oldValue === newValue) {
              return null;
            }

            return (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right" sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {oldValue}
                </TableCell>
                <TableCell align="right" sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {newValue}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  function handleClick() {
    window.open(`/auditoria/cadastro/${row.id}`, "_blank");
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.data}</TableCell>
        <TableCell>{row.camada}</TableCell>
        <TableCell>{row.descricao}</TableCell>
        <TableCell>{row.usuario}</TableCell>
        <TableCell>{row.descricao}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", borderBottom: "none" }}>
          {countChanges(row.body_antigo, row.body_novo)}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "6px",
                      flexDirection: "column",
                      minWidth: "200px",
                      width: "100%",
                      maxWidth: "300px",
                    }}
                  >
                    <Box>
                      <b>Descrição</b>
                    </Box>
                    <Box>
                      <b>id: </b>
                      <span>{row?.id}</span>
                    </Box>
                    <Box>
                      <b>Data: </b>
                      <span>{moment(row?.data).format("DD/MM/YYYY HH:mm:ss")}</span>
                    </Box>
                    <Box>
                      <b>Camada: </b>
                      <span>{row?.camada}</span>
                    </Box>
                    <Box>
                      <b>Descrição: </b>
                      <span>{row?.descricao}</span>
                    </Box>
                    <Box>
                      <b>Usuário: </b>
                      <span>{row.usuario}</span>
                    </Box>
                    <Box>
                      <b>Mensagem: </b>
                      <span>-</span>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "6px", flexDirection: "column", width: "100%" }}>
                    <b>Valores modificados</b>
                    {currentComponent()}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "12px",
                    marginTop: "12px",
                  }}
                >
                  <Button onClick={handleClick} variant="contained" color="success">
                    Visualizar em detalhes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
